import request from 'superagent';
import { auth } from './App';

let BASE_URL = 'https://yt-tristan.herokuapp.com';
if (window && window.location.hostname.indexOf('localhost') > -1) {
  BASE_URL = 'http://localhost:3001';
}

export function addSingle(data) {
  const { payload, privacy, delay, noAudio } = data;
  const apiUrl = `${BASE_URL}/single-video`;
  return request
    .post(apiUrl)
    .set('Authorization', `Bearer ${auth.getIdToken()}`)
    .send({
      payload,
      privacy,
      delay,
      noAudio,
    });
}

export function addBulk(data) {
  const { payload, privacy, delay, noAudio, from, to } = data;
  const apiUrl = `${BASE_URL}/bulk-videos`;
  return request
    .post(apiUrl)
    .set('Authorization', `Bearer ${auth.getIdToken()}`)
    .send({
      payload,
      privacy,
      delay,
      start: from,
      end: to,
      noAudio,
    });
}

export function loginGoogle() {
  window.location = `${BASE_URL}/login_google`;
}

export function logoutGoogle(googleUserId) {
  return request
    .post(`${BASE_URL}/logout_google`)
    .send({ googleUserId })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}

export function exchangeGoogleCode(code) {
  return request
    .post(`${BASE_URL}/code_google`)
    .send({
      code,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}

export function loginDropbox() {
  window.location = `${BASE_URL}/login_dropbox`;
}

export function logoutDropbox(dropboxUserId) {
  return request
    .post(`${BASE_URL}/logout_dropbox`)
    .send({ dropboxUserId })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}

export function exchangeDropboxCode(code) {
  return request
    .post(`${BASE_URL}/code_dropbox`)
    .send({
      code,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}

export function checkLoggedInAccounts() {
  return request
    .get(`${BASE_URL}/accounts`)
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}

export function queueTransfer() {
  return request
    .get(`${BASE_URL}/queue/transfer`)
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueMerge() {
  return request
    .get(`${BASE_URL}/queue/merge`)
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueClear() {
  return request
    .get(`${BASE_URL}/queue/clear`)
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueBackup() {
  return request
    .get(`${BASE_URL}/queue/backup`)
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueRemoveElement(id) {
  return request
    .post(`${BASE_URL}/queue/removeElement`)
    .send({
      id,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueSort(sortBy, orderBy) {
  return request
    .post(`${BASE_URL}/queue/sort`)
    .send({
      sortBy,
      orderBy,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueSetStartTime(index, value) {
  return request
    .post(`${BASE_URL}/queue/setStartTime`)
    .send({
      index,
      value,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueSetEndTime(index, value) {
  return request
    .post(`${BASE_URL}/queue/setEndTime`)
    .send({
      index,
      value,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueFilterByTitle(title) {
  return request
    .post(`${BASE_URL}/queue/filterByTitle`)
    .send({
      title,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
export function queueSetItemDelay(index, delay) {
  return request
    .post(`${BASE_URL}/queue/setItemDelay`)
    .send({
      index,
      delay,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}

export function queueSetAllDelays(delay) {
  return request
    .post(`${BASE_URL}/queue/setAllDelays`)
    .send({
      delay,
    })
    .set('Authorization', `Bearer ${auth.getIdToken()}`);
}
