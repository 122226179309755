import React from 'react';
import { Router, Route } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { Provider } from 'react-redux';
import { Spinner } from 'evergreen-ui';
import { WebSocketLink } from 'apollo-link-ws';
import PrivateRoute from './PrivateRoute';
import HomeNotLogged from './routes/HomeNotLogged';
import Home from './routes/Home';
import AuthService from './AuthService';
import history from './history';
import store from './models';
import Header from './components/Header';

export const auth = new AuthService();

let WS_URL = 'wss://yt-tristan.herokuapp.com/graphql';
if (window && window.location.hostname.indexOf('localhost') > -1) {
  WS_URL = 'ws://localhost:3001/graphql';
}

const wsLink = new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true,
    connectionParams: () => ({
      authToken: auth.getIdToken(),
    }),
  },
});

let BASE_URL = 'https://yt-tristan.herokuapp.com';
if (window && window.location.hostname.indexOf('localhost') > -1) {
  BASE_URL = 'http://localhost:3001';
}

// Create an http link:
const httpLink = new HttpLink({
  uri: `${BASE_URL}/api/graphql`,
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  request: async operation => {
    const token = auth.getIdToken();
    if (token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }
  },
});

const App = () => {
  const { renewSession } = auth;
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Router history={history}>
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <Header />
            <Route
              path="/"
              exact
              render={() => {
                if (localStorage.getItem('isLoggedIn') === 'true') {
                  renewSession();
                }
                return <HomeNotLogged />;
              }}
            />
            <Route
              path="/auth_callback"
              render={() => {
                auth.handleAuthentication();
                return <Spinner margin={20} />;
              }}
            />
            <Route
              path="/auth_google_callback"
              render={() => {
                if (localStorage.getItem('isLoggedIn') === 'true') {
                  renewSession(false).then(() =>
                    auth.handleAuthenticationGoogle(),
                  );
                }
                return <Spinner margin={20} />;
              }}
            />
            <Route
              path="/auth_dropbox_callback"
              render={() => {
                if (localStorage.getItem('isLoggedIn') === 'true') {
                  renewSession(false).then(() =>
                    auth.handleAuthenticationDropbox(),
                  );
                }
                return <Spinner margin={20} />;
              }}
            />
            <PrivateRoute path="/tool" component={Home} />
          </div>
        </Router>
      </ApolloProvider>
    </Provider>
  );
};

export default App;
