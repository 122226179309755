import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Button,
  IconButton,
  Heading,
  Paragraph,
  TextInputField,
  Pane,
  Link,
  Tooltip,
} from 'evergreen-ui';
import moment from 'moment';
import {
  queueRemoveElement,
  queueSetStartTime,
  queueSetEndTime,
  queueSetItemDelay,
} from '../../apiClient';

const formatTimeNumber = (num) => {
  const stringNum = num.toString();
  if (stringNum.length === 2) {
    return stringNum;
  }
  if (stringNum.length === 1) {
    return `0${stringNum}`;
  }
  return '';
};

const formatTime = (length) => {
  const seconds = parseInt(length, 10);
  const duration = moment.duration(seconds, 'seconds');
  const h = formatTimeNumber(duration.hours());
  const m = formatTimeNumber(duration.minutes());
  const s = formatTimeNumber(duration.seconds());
  return `${h}:${m}:${s}`;
};

const convertTimeToSeconds = timeString => timeString
  .split(':')
  .reverse()
  .reduce((total, currentVal, currentIndex) => {
    let val = parseInt(currentVal, 10);
    if (currentIndex > 0) {
      val *= 60;
    }
    if (currentIndex > 1) {
      val *= 60;
    }
    return total + val;
  }, 0);

const QueueItem = ({ item, index, inProgress }) => {
  const {
    metadata, delay, id, status, payload, error, startTime, endTime, createdResource, source,
  } = item;
  const [customDelay, setCustomDelay] = useState(delay);
  const [start, setStart] = useState(formatTime(startTime));
  const [end, setEnd] = useState(formatTime(endTime));
  const [loadingRemoveElement, setLoadingRemoveElement] = useState(false);
  const [loadingStartEndTime, setLoadingStartEndTime] = useState(false);
  const [loadingCustomDelay, setLoadingCustomDelay] = useState(false);
  useEffect(() => {
    if (delay !== customDelay) {
      setCustomDelay(delay);
    }
  }, [delay]);
  return (
    <Table.Row intent={status === 'error' ? 'danger' : 'none'} height="auto" paddingY={12} key={id}>
      <Table.Cell flexBasis={120} flexShrink={0} flexGrow={0}>
        <Pane flexDirection="column" justifyContent="flex-start">
          <Pane marginBottom={10}>
            <Paragraph>{status}</Paragraph>
            {status === 'error' && error && <Paragraph>{error}</Paragraph>}
          </Pane>
          <Tooltip content="Remove from queue">
            <IconButton
              disabled={inProgress}
              appearance="primary"
              onClick={() => {
                setLoadingRemoveElement(true);
                queueRemoveElement(id)
                  .catch(() => {})
                  .finally(() => setLoadingRemoveElement(false));
              }}
              icon="trash"
              intent="danger"
              height={32}
              isLoading={loadingRemoveElement}
            />
          </Tooltip>
        </Pane>
      </Table.Cell>
      <Table.Cell flexBasis={220} flexShrink={0} flexGrow={0}>
        <Pane display="flex" flexDirection="row">
          <TextInputField
            marginBottom={10}
            width={45}
            min="1"
            type="number"
            label="Delay"
            value={index === 0 ? 0 : customDelay}
            onChange={e => setCustomDelay(e.target.value)}
            marginRight={10}
            disabled={index === 0 || inProgress}
          />
          <Pane marginTop={24}>
            {index !== 0 && (
              <Button
                marginRight={10}
                appearance="primary"
                onClick={() => {
                  setLoadingCustomDelay(true);
                  queueSetItemDelay(id, customDelay)
                    .catch(() => {})
                    .finally(() => setLoadingCustomDelay(false));
                }}
                isLoading={loadingCustomDelay}
                disabled={inProgress}
              >
                Set
              </Button>
            )}
            {index !== 0 && delay !== 2 && (
              <Button
                onClick={() => {
                  setLoadingCustomDelay(true);
                  queueSetItemDelay(id, 2)
                    .catch(() => {})
                    .finally(() => {
                      setCustomDelay(2);
                      setLoadingCustomDelay(false);
                    });
                }}
                isLoading={loadingCustomDelay}
                disabled={inProgress}
              >
                Reset
              </Button>
            )}
          </Pane>
        </Pane>
      </Table.Cell>
      <Table.Cell flexDirection="row" justifyContent="flex-start">
        {metadata && metadata.thumbnails && metadata.thumbnails.default && (
          <Link target="_blank" href={payload} marginRight={20}>
            <img
              alt="thumbnail"
              src={metadata.thumbnails.default}
              style={{ maxHeight: 80, backgroundColor: '#ddd' }}
            />
          </Link>
        )}
        <Pane flexDirection="column">
          <Heading size={500}>{metadata.title}</Heading>
          {metadata.channelId && (
            <Paragraph>
              <Link target="_blank" href={`https://www.youtube.com/channel/${metadata.channelId}`}>
                {metadata.channelTitle}
              </Link>
            </Paragraph>
          )}
          <Paragraph>Duration: {formatTime(metadata.length)}</Paragraph>
          <Paragraph>Resolution: {metadata.maxResolution}</Paragraph>
          {createdResource && createdResource.url && (
            <Paragraph>
              Created video url:{' '}
              <Link target="_blank" href={createdResource.url}>
                {createdResource.url}
              </Link>
            </Paragraph>
          )}
        </Pane>
      </Table.Cell>
      <Table.Cell>
        <Pane display="flex" flexDirection="column" justifyContent="center">
          <Pane display="flex" flexDirection="row">
            <TextInputField
              width={80}
              marginBottom={10}
              label="Start time"
              value={start}
              onChange={e => setStart(e.target.value)}
              marginRight={10}
              disabled={inProgress || source === 'dropbox'}
            />
            <TextInputField
              width={80}
              marginBottom={10}
              label="End time"
              value={end}
              onChange={(e) => {
                setEnd(e.target.value);
              }}
              marginRight={10}
              disabled={inProgress || source === 'dropbox'}
            />
            <Pane marginTop={24}>
              <Button
                marginRight={10}
                appearance="primary"
                onClick={() => {
                  setLoadingStartEndTime(true);
                  const s = convertTimeToSeconds(start);
                  const e = convertTimeToSeconds(end);
                  Promise.all([queueSetStartTime(id, s), queueSetEndTime(id, e)])
                    .catch(() => {})
                    .finally(() => setLoadingStartEndTime(false));
                }}
                isLoading={loadingStartEndTime}
                disabled={inProgress || source === 'dropbox'}
              >
                Set
              </Button>
              {(metadata.length !== endTime || startTime !== 0) && (
                <Button
                  onClick={() => {
                    setLoadingStartEndTime(true);
                    Promise.all([queueSetStartTime(id, 0), queueSetEndTime(id, metadata.length)])
                      .catch(() => {})
                      .finally(() => {
                        setEnd(formatTime(metadata.length));
                        setStart(formatTime(0));
                        setLoadingStartEndTime(false);
                      });
                  }}
                  isLoading={loadingStartEndTime}
                  disabled={inProgress || source === 'dropbox'}
                >
                  Reset
                </Button>
              )}
            </Pane>
          </Pane>
        </Pane>
      </Table.Cell>
    </Table.Row>
  );
};

QueueItem.propTypes = {
  item: PropTypes.shape({
    status: PropTypes.string.isRequired,
    metadata: PropTypes.shape().isRequired,
    id: PropTypes.string.isRequired,
    delay: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default QueueItem;
