import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Heading,
  Pane,
  Text,
  Button,
  Spinner,
  TextInput,
  Select,
  Pre,
} from 'evergreen-ui';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import {
  loginGoogle,
  queueTransfer,
  queueClear,
  queueBackup,
  queueMerge,
  queueSort,
  queueFilterByTitle,
  loginDropbox,
  queueSetAllDelays,
} from '../../apiClient';

import QueueTable from './QueueTable';
import { QUEUE_UPDATED, GET_QUEUE } from '../../graphql';

const calcTotalTimeFromItems = items => items.reduce((total, item, i) => {
  if (i === items.length - 1) {
    return total;
  }
  if (item.status !== 'uploaded' && item.status !== 'backedUp' && item.status !== 'error') {
    return total + Number(item.delay);
  }
  return total;
}, 0);

class Queue extends Component {
  state = {
    filterByTitle: '',
    sortBy: 'publishedAt',
    orderBy: 'asc',
    allDelays: 2,
    loadingCustomDelay: false,
  };

  componentDidMount() {
    const startDropbox = localStorage.getItem('startCopyDropbox');
    const startMerge = localStorage.getItem('startMerge');
    if (startDropbox) {
      queueBackup().catch(() => {});
      this.removeStartCopyDropbox();
    }
    if (startMerge) {
      queueMerge().catch(() => {});
      this.removeStartMerge();
    }
  }

  saveStartCopyDropbox = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('startCopyDropbox', 'true');
    }
  };

  removeStartCopyDropbox = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('startCopyDropbox');
    }
  };

  saveStartMerge = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('startMerge', 'true');
    }
  };

  removeStartMerge = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('startMerge');
    }
  };

  render() {
    const {
      filterByTitle, sortBy, orderBy, allDelays, loadingCustomDelay,
    } = this.state;
    const { googleProfile, dropboxProfile } = this.props;
    return (
      <Query query={GET_QUEUE}>
        {({
          data, loading, error, subscribeToMore,
        }) => {
          if (loading) {
            return <Spinner />;
          }
          if (error) {
            return <Pre>{error.message}</Pre>;
          }
          const { getQueue } = data;
          const { inProgress, youtubeAccount, mergeable } = getQueue.info;
          subscribeToMore({
            document: QUEUE_UPDATED,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return {
                getQueue: subscriptionData.data.queueUpdated,
              };
            },
          });
          const emptyQueue = getQueue.items.length === 0;
          return (
            <div>
              <Pane display="flex" flexDirection="row" alignItems="baseline" marginBottom={10}>
                {inProgress ? (
                  <Heading color="green" size={600} marginRight={20}>
                    Queue in progress
                    {inProgress && youtubeAccount && `, uploading to '${youtubeAccount}'`}
                  </Heading>
                ) : (
                  <Heading size={600} marginRight={20}>
                    Queue
                  </Heading>
                )}
                <Text marginRight={20}>
                  Estimated time: ~{calcTotalTimeFromItems(getQueue.items)}min
                </Text>
              </Pane>
              <Pane marginBottom={10}>
                <Button
                  onClick={() => {
                    if (googleProfile) {
                      queueTransfer().catch(() => {});
                    } else {
                      loginGoogle();
                    }
                  }}
                  iconBefore="play"
                  intent="success"
                  appearance="primary"
                  marginRight={10}
                  disabled={inProgress || emptyQueue}
                >
                  Copy to Youtube
                </Button>
                <Button
                  onClick={async () => {
                    if (dropboxProfile) {
                      queueMerge().catch(() => {});
                    } else {
                      this.saveStartMerge();
                      loginDropbox();
                    }
                  }}
                  iconBefore="play"
                  intent="warning"
                  appearance="primary"
                  marginRight={10}
                  disabled={inProgress || !mergeable || emptyQueue}
                >
                  Merge
                </Button>
                <Button
                  onClick={() => {
                    if (dropboxProfile) {
                      queueBackup().catch(() => {});
                    } else {
                      this.saveStartCopyDropbox();
                      loginDropbox();
                    }
                  }}
                  iconBefore="play"
                  intent="success"
                  appearance="primary"
                  marginRight={10}
                  disabled={inProgress || emptyQueue}
                >
                  Copy to Dropbox
                </Button>
                <Button
                  onClick={async () => {
                    await queueClear();
                  }}
                  iconBefore="trash"
                  intent="danger"
                  appearance="primary"
                  marginRight={10}
                  disabled={emptyQueue}
                >
                  Stop & Delete Queue
                </Button>
              </Pane>
              <Pane justifyContent="center" alignItems="center" marginBottom={10}>
                <Text marginRight={10}>Filter by title</Text>
                <TextInput
                  onChange={e => this.setState({ filterByTitle: e.target.value })}
                  width={220}
                  value={filterByTitle}
                  marginRight={4}
                  disabled={inProgress}
                />
                <Button
                  disabled={filterByTitle === '' || inProgress}
                  onClick={() => queueFilterByTitle(filterByTitle).catch(() => {})}
                  marginRight={20}
                >
                  Filter
                </Button>
                <Text marginRight={10}>Sort by</Text>
                <Select
                  onChange={(e) => {
                    const newSortBy = e.target.value;
                    this.setState({ sortBy: newSortBy });
                    queueSort(newSortBy, orderBy).catch(() => {});
                  }}
                  width={130}
                  marginRight={20}
                  disabled={inProgress}
                >
                  <option value="publishedAt">Published At</option>
                  <option value="viewCount">View count</option>
                </Select>
                <Text marginRight={10}>Order by</Text>
                <Select
                  onChange={(e) => {
                    const newOrderBy = e.target.value;
                    this.setState({ orderBy: newOrderBy });
                    queueSort(sortBy, newOrderBy).catch(() => {});
                  }}
                  width={80}
                  disabled={inProgress}
                  marginRight={20}
                >
                  <option value="asc">ASC</option>
                  <option value="desc">DESC</option>
                </Select>
                <Text marginRight={10}>Set all delays</Text>
                <TextInput
                  width={45}
                  min="1"
                  type="number"
                  value={allDelays}
                  onChange={e => this.setState({ allDelays: e.target.value })}
                  marginRight={10}
                  disabled={inProgress}
                />
                <Button
                  marginRight={10}
                  appearance="primary"
                  onClick={() => {
                    this.setState({ loadingCustomDelay: true });
                    queueSetAllDelays(allDelays)
                      .catch(() => {})
                      .finally(() => this.setState({ loadingCustomDelay: false }));
                  }}
                  isLoading={loadingCustomDelay}
                  disabled={inProgress}
                >
                  Set all
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ loadingCustomDelay: true });
                    queueSetAllDelays(2)
                      .catch(() => {})
                      .finally(() => {
                        this.setState({ loadingCustomDelay: false });
                      });
                  }}
                  isLoading={loadingCustomDelay}
                  disabled={inProgress}
                >
                  Reset all
                </Button>
              </Pane>
              <QueueTable items={getQueue.items} inProgress={inProgress} />
            </div>
          );
        }}
      </Query>
    );
  }
}

Queue.propTypes = {
  googleProfile: PropTypes.shape({
    picture: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  dropboxProfile: PropTypes.shape(),
};

const mapStateToProps = ({ user }) => ({
  googleProfile: user.googleProfile,
  dropboxProfile: user.dropboxProfile,
});

export default connect(mapStateToProps)(Queue);
