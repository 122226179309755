import gql from 'graphql-tag';

export const GET_QUEUE = gql`
  query {
    getQueue {
      info {
        mergeable
        backupable
        inProgress
        youtubeAccount
        dropboxAccount
      }
      items {
        id
        status
        source
        error
        delay
        payload
        privacy
        startTime
        endTime
        createdResource {
          url
        }
        metadata {
          title
          description
          length
          maxResolution
          channelTitle
          channelId
          thumbnails {
            default
          }
        }
      }
    }
  }
`;

export const QUEUE_UPDATED = gql`
  subscription {
    queueUpdated {
      info {
        mergeable
        backupable
        inProgress
        youtubeAccount
        dropboxAccount
      }
      items {
        id
        status
        error
        delay
        payload
        privacy
        startTime
        endTime
        source
        createdResource {
          url
        }
        metadata {
          title
          description
          length
          maxResolution
          channelTitle
          channelId
          thumbnails {
            default
          }
        }
      }
    }
  }
`;
