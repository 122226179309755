import auth0 from 'auth0-js';
import jwt from 'jsonwebtoken';
import history from './history';
import store from './models';
import {
  exchangeGoogleCode,
  checkLoggedInAccounts,
  exchangeDropboxCode,
  queueTransfer,
} from './apiClient';

const { dispatch } = store;

let BASE_URL = 'https://studio.yoplaymedia.com';
if (window && window.location.hostname.indexOf('localhost') > -1) {
  BASE_URL = 'http://localhost:3000';
}

class AuthService {
  auth0 = new auth0.WebAuth({
    domain: 'yoplaymedia.eu.auth0.com',
    clientID: '2x8MhEuasgXWUyj2BAeb3NDpKbvpslUx',
    redirectUri: `${BASE_URL}/auth_callback`,
    responseType: 'token id_token',
    scope: 'openid profile email',
    approval_prompt: 'force',
  });

  login = () => {
    this.auth0.authorize();
  };

  checkLoggedInAccounts = async () => {
    dispatch.user.setIsLoggingWithGoogle(true);
    dispatch.user.setIsLoggingWithDropbox(true);
    try {
      const res = await checkLoggedInAccounts();
      const { accounts } = res.body;
      if (accounts.google) {
        dispatch.user.setGoogleProfile(accounts.google);
      } else {
        dispatch.user.setGoogleProfile(null);
      }
      if (accounts.dropbox) {
        dispatch.user.setDropboxProfile(accounts.dropbox);
      } else {
        dispatch.user.setDropboxProfile(null);
      }
      dispatch.user.setIsLoggingWithDropbox(false);
      dispatch.user.setIsLoggingWithGoogle(false);
    } catch (err) {
      dispatch.user.setIsLoggingWithDropbox(false);
      dispatch.user.setIsLoggingWithGoogle(false);
    }
  };

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        history.replace('/');
        dispatch.user.setLogggedIn(false);
        console.log(err);
      }
    });
  };

  handleAuthenticationGoogle = async () => {
    dispatch.user.setIsLoggingWithGoogle(true);
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const exchangeCode = await exchangeGoogleCode(code, this.idToken);
    const { idToken } = exchangeCode.body;
    const decoded = jwt.decode(idToken);
    dispatch.user.setGoogleProfile(decoded);
    dispatch.user.setIsLoggingWithGoogle(false);
    history.replace('/tool');
    await queueTransfer();
  };

  handleAuthenticationDropbox = async () => {
    dispatch.user.setIsLoggingWithDropbox(true);
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const exchangeCode = await exchangeDropboxCode(code, this.idToken);
    const { profile } = exchangeCode.body;
    dispatch.user.setDropboxProfile(profile);
    dispatch.user.setIsLoggingWithDropbox(false);
    history.replace('/tool');
  };

  getAccessToken = () => this.accessToken;

  getIdToken = () => this.idToken;

  getProfile = () =>
    new Promise((resolve, reject) => {
      this.auth0.client.userInfo(this.accessToken, (err, profile) => {
        if (err) {
          reject(err);
        }
        resolve(profile);
      });
    });

  setSession = (authResult, withRedirect = true) => {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    dispatch.user.setLogggedIn(true);
    dispatch.user.getProfile();

    // navigate to the home route
    if (withRedirect) {
      history.replace('/tool');
      this.checkLoggedInAccounts();
    }
  };

  renewSession = async withRedirect =>
    new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) {
          this.logout();
          console.error(err);
          reject(err);
          return;
        }
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult, withRedirect);
          this.checkLoggedInAccounts();
          resolve();
        }
      });
    });

  logout = () => {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');

    dispatch.user.setLogggedIn(false);
    dispatch.user.setProfile(null);
    dispatch.user.setGoogleProfile(null);
    dispatch.user.setDropboxProfile(null);

    this.auth0.logout({
      clientID: '2x8MhEuasgXWUyj2BAeb3NDpKbvpslUx',
      returnTo: window.location.origin,
    });

    // navigate to the home route
    history.replace('/');
  };

  isAuthenticated = () => new Date().getTime() < this.expiresAt;
}

export default AuthService;
